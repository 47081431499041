import Link from 'next/link';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { CLIENT_ROUTE } from '@common/constants';
import { CLIENT_FORM } from '@common/schema/flow';
import { SelectCity } from '@modules/flow/SelectCity';

const SearchComponent = ({ styles }: any) => {
  const methods = useForm({});
  const { watch } = methods;

  const city = watch(CLIENT_FORM.CITY);

  const getHref = () => {
    if (city && JSON.parse(city)) {
      return {
        pathname: CLIENT_ROUTE.FLOW,
        query: { query: city },
      };
    } else {
      return '#';
    }
  };
  return (
    <div className={styles.search_blk}>
      <div className={styles.searchbar_container}>
        <FormProvider {...methods}>
          <SelectCity styles={styles} />
        </FormProvider>
        <div className={styles.search_btn}>
          <Link href={getHref()} className={styles.search_btn}>
            <a
              className={styles.btn_accent}
              style={{ width: 120, marginLeft: 10 }}>
              Get Price
            </a>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SearchComponent;
